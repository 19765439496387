import { Disclosure, Transition } from "@headlessui/react";
import Navigation, { getNavigationProps } from "../navigation/navigation";

import { CrossIcon } from "components/icons";
import Link from "next/link";

const Hamburger = () => (
  <div className="flex flex-col justify-evenly w-5 h-5" aria-label="Open Menu" role="button">
    <span className="border border-blue-500 w-full" />
    <span className="border border-blue-500 w-full" />
    <span className="border border-blue-500 w-full" />
  </div>
);

const Header = ({ navigationProps }) => {
  return (
    <header className="w-full py-8 flex flex-col items-stretch justify-center">
      <div className="max-w-7xl mx-auto w-full px-4 flex flex-col">
        <Disclosure>
          {({ open }) => (
            <>
              <div className="flex flex-row justify-between items-center">
                <Link href="/" passHref>
                  <a className="block w-[180px] h-[40px] md:w-[448px] md:h-[60px]">
                    <img
                      src="/_next/static/media/logo.c801163c.svg"
                      alt="Cheap Airport Parking"
                      className="w-full h-full object-contain"
                    />
                  </a>
                </Link>
                <div className="hidden md:flex pr-6 flex-row items-center">
                  <Navigation key="desktop-nav" className="flex-row" {...navigationProps} />
                </div>
                <Disclosure.Button className="block md:hidden p-3">
                  {open ? <CrossIcon className="w-5 h-5 text-blue-500" aria-label="Close Menu" /> : <Hamburger />}
                </Disclosure.Button>
              </div>

              <Transition
                enter="transition duration-300 ease-out"
                enterFrom="transform scale-y-0 origin-top opacity-50"
                enterTo="transform scale-y-100 origin-top opacity-100"
                leave="transition duration-300 ease-in"
                leaveFrom="transform scale-y-100 origin-top opacity-100"
                leaveTo="transform scale-y-0 origin-top opacity-0"
              >
                <Disclosure.Panel className="bg-white z-10 pt-10 block md:hidden">
                  {({ close }) => (
                    <Navigation
                      key="mobile-nav"
                      className="flex-col text-lg"
                      {...navigationProps}
                      withDividers
                      onNavigate={() => close()}
                    />
                  )}
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
    </header>
  );
};

export default Header;

export async function getHeaderProps(options = {}) {
  return {
    navigationProps: await getNavigationProps(),
  };
}
