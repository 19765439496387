import Image from "next/image";
import Link from "next/link";
import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const MarkdownText = ({ children, prose = false, className = "", ...props }) => {
  return (
    <ReactMarkdown
      {...props}
      className={`markdown-text-wrapper ${prose ? 'prose prose-headings:text-pink-500 prose-headings:font-normal max-w-none': ''} ${className}`}
      rehypePlugins={[rehypeRaw]}
      components={{
        a({ node, children, href, ...aProps }) {
          const isExternal = href.startsWith("http");
          return (
            <Link href={href}>
              <a
                {...aProps}
                target={isExternal ? "_blank" : undefined}
                rel={isExternal ? "noopener" : undefined}
              >
                {children}
              </a>
            </Link>
          );
        },
        img({ src, alt, width, height, layout }) {
          const isResponsive = !width && !height;
          return (
            <div className={`next-image-wrapper w-full ${isResponsive ? 'aspect-h-9 aspect-w-16' : ''}`}>
              <Image
                src={src}
                alt={alt}
                width={isResponsive ? '1920px' : width}
                height={isResponsive ? '1080px' : height}
                layout={isResponsive ? 'responsive' : (layout || 'intrinsic')}
                unoptimized // ✅ disables Vercel image optimization
              />
            </div>
          );
        }
      }}
    >
      {children}
    </ReactMarkdown>
  );
};

export default MarkdownText;
