import { ClockIcon, PinIcon } from "components/icons";

import Image from 'next/future/image'

export function InfoWithIcon({ icon, children }) {
    return (
        <div className="flex flex-row gap-2 items-center text-gray-500 text-md">
            <span>{icon}</span>
            <span>{children}</span>
        </div>
    );
}

export function TransferTime({ children }) {
    return (children !== null && typeof children !== 'undefined') ? (
        <InfoWithIcon icon={<ClockIcon className="h-5 w-5" />}>{children} minute transfer</InfoWithIcon>
    ) : null;
}

export function Terminals({ airportTerminals, terminals }) {
    const displayTerminals = terminals.map(terminalObj => terminalObj.attributes.name);
    if (displayTerminals.length === 0) return null;
    return <InfoWithIcon icon={<PinIcon className="h-5 w-5" />}>{displayTerminals.join(", ")}</InfoWithIcon>;
}

export function ParkMarkAwardBadge() {
  return (
    <div className="w-[180px] h-10 flex flex-row bg-white rounded-lg shadow-lg">
      <div className='flex-1 max-w-[70px] flex justify-center items-center bg-sky-600 rounded-l-lg text-white'>
        <div className="w-full h-full rounded-l-lg overflow-hidden">
          <Image
            src={require("images/park-mark.png")}
            width={770}
            height={385}
            alt={"Park Mark Award"}
            className="rounded-l-lg object-cover object-center"
            unoptimized // ✅ disables Vercel image optimization
          />
        </div>
      </div>
      <div className='flex-1 flex justify-center items-center py-1 px-1 rounded-r-lg'>
        <span className="text-pink-500 text-xs">Park Mark Award</span>
      </div>
    </div>
  );
}


export function LowestPriceBadge() {
    return (
        <div className="w-32 h-10 flex flex-row bg-pink-900 rounded-lg py-2 px-3 text-center justify-center items-center shadow-lg">
            <span className="text-white font-semibold">Lowest price</span>
        </div>
    );
}

export function GenericBadge({ children }) {
    return (
        <div className="w-32 h-10 flex flex-row bg-white rounded-lg py-2 px-3 text-center justify-center items-center shadow-lg">
            <span className="text-pink-500 text-xs">{children}</span>
        </div>
    );
}
