import { ParkMarkAwardBadge, TransferTime } from 'components/atoms/info'

import { Fragment } from 'react'
import Image from 'next/image'
import MapSection from './map';
import MarkdownText from 'components/atoms/markdown';
import { Tab } from '@headlessui/react';

function ProductSummary({ name, transferTimeMinutes, hasParkMarkAward, summaryText }) {
    return (
        <div className='flex-1 flex flex-col justify-between gap-4'>
            <div className="flex-1">
                <h2 className='font-bold text-3xl text-pink-900'>{name}</h2>
                <TransferTime>{transferTimeMinutes}</TransferTime>
                {summaryText && <MarkdownText prose className="mt-2">{summaryText}</MarkdownText>}
            </div>
            <div>
                {hasParkMarkAward && <ParkMarkAwardBadge />}
            </div>
        </div>
    )
}

function AirportSummary({ name, address }) {
    return (
        <div className='flex-1 flex flex-col justify-between gap-4'>
            <div className="flex-1">
                <h2 className='font-bold text-3xl text-pink-900'>{name}</h2>
                <MarkdownText prose className="prose-p:m-0">{address}</MarkdownText>
            </div>
        </div>
    )
}

function RenderContent({ tabData }) {
    if (tabData.productSummary?.name) return <ProductSummary {...tabData.productSummary} />;
    if (tabData.airportSummary?.name) return <AirportSummary {...tabData.airportSummary} />;
    if (tabData.mapView) return <MapSection {...tabData.mapView} />;
    return (
        <div>
            {/* Title here if it needs re-enabled */}
            {/* <h2 className='font-bold text-xl text-pink-900'>{tabData.name}</h2> */}
            <MarkdownText prose>{tabData.content}</MarkdownText>
        </div>
    );
}

export function RawTabbedSection({ 
    tabs, defaultImage, className = '', innerClassName = '', panelClassName = '', selectedTabClassName = '', tabListClassName='',
}) {
    return (
        <section className={`${className||''}`}>
            <div className={`max-w-6xl mx-auto ${innerClassName||''}`}>
                <Tab.Group>
                    {(tabs||[]).length > 1 && (
                        <Tab.List className={`flex flex-row gap-1 overflow-x-scroll ${tabListClassName}`}>
                            {tabs.map((tab, idx) => (
                                <Tab as={Fragment} key={idx}>
                                    {({ selected }) => (
                                        <button
                                            className={`
                                                rounded-t-lg py-2 px-4 text-pink-900 text-lg whitespace-nowrap
                                                ${selected 
                                                    ? 'font-bold shadow ' + selectedTabClassName||'' 
                                                    : 'font-normal hover:bg-gray-200 px-6'}
                                            `}
                                        >
                                            {tab.name}
                                        </button>
                                    )}
                                </Tab>
                            ))}
                        </Tab.List>
                    )}
                    <Tab.Panels className="w-full h-full">
                        {(tabs||[]).map((tabData) => {
                            const { name, image } = tabData;
                            const imageData = image?.data?.attributes?.url ? image.data.attributes : defaultImage?.data?.attributes;
                            return (
                                <Tab.Panel key={name} className={
                                    `p-4 rounded-b rounded-tr shadow ${panelClassName||''}
                                `}>
                                    <div className='flex flex-col md:flex-row gap-4'>
                                       {imageData?.url && !tabData.mapView && (
  <div className="flex-1 p-6 md:max-w-[30%]">
    <Image
      src={imageData.url}
      width={imageData.width}
      height={imageData.width}
      alt={imageData.alternativeText}
      layout="responsive"
      unoptimized
    />
  </div>
)}
                                        <div className='flex-1 p-6 flex flex-col'>
                                            <RenderContent tabData={tabData} />
                                        </div>
                                    </div>
                                </Tab.Panel>
                            );
                        })}
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </section>
    );
}

export default function TabbedSection(props) {
    return (
        <RawTabbedSection 
            {...props}
            className="py-10"
            innerClassName="px-2"
            panelClassName="bg-pink-100"
            selectedTabClassName="bg-pink-100 hover:bg-pink-100/80"
        />
    )
}

export const PRODUCT_TABS_POPULATE_FIELDS = ["image", "customProductInformation", "productFilteringData", "customTabs", "customTabs.tabs", "customTabs.tabs.image"];
export function parseProductTabsProps(attributes) {
    const { name, image, customProductInformation, customTabs, productFilteringData, summaryText } = attributes;

    const tabs = [
        {
            name: 'Summary',
            productSummary: {
                name,
                transferTimeMinutes: productFilteringData?.transferTimeMinutes || null,
                hasParkMarkAward: productFilteringData?.hasParkMarkAward || null,
                summaryText: summaryText || null,
            },
        },
    ];

    if (customProductInformation?.directions) {
        tabs.push({
            name: 'Directions',
            content: customProductInformation.directions,
        });
    }
    if (customProductInformation?.onArrival) {
        tabs.push({
            name: 'On arrival',
            content: customProductInformation.onArrival,
        });
    }
    if (customProductInformation?.onReturn) {
        tabs.push({
            name: 'On return',
            content: customProductInformation.onReturn,
        });
    }
    if (customProductInformation?.parkmarkAwardInfo) {
        tabs.push({
            name: 'Park Mark Award Info',
            content: customProductInformation.parkmarkAwardInfo,
        });
    }

    tabs.push(...(customTabs?.tabs || []));

    return {
        defaultImage: image,
        tabs,
    }
}
